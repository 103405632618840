import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { WHITE } = colors;
const { ICON_ABOUT_SOLID } = ReactTestingLibraryDataProperties;

const AboutSolid: FC<Icon> = ({ size = 1.5, color = WHITE, fillOpacity = 1 }) => (
  <Container size={size} testId={ICON_ABOUT_SOLID}>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M10 1.664a8.335 8.335 0 110 16.67 8.335 8.335 0 010-16.67zM9.997 8.54a.833.833 0 00-.828.736l-.005.097.003 4.585.006.097a.833.833 0 001.655 0l.006-.098-.003-4.585-.006-.097a.833.833 0 00-.828-.735zM10 5.415A1.043 1.043 0 1010 7.5a1.043 1.043 0 000-2.086z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  </Container>
);

export default AboutSolid;
