import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { WHITE } = colors;
const { ICON_CONTRAST } = ReactTestingLibraryDataProperties;

const Contrast: FC<Icon> = ({ size, color = WHITE, fillOpacity = 1 }) => (
  <Container size={size} testId={ICON_CONTRAST}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      fillOpacity={fillOpacity}
    >
      <path d="M16 7.667c-4.602 0-8.333 3.73-8.333 8.333 0 4.602 3.73 8.333 8.333 8.333 4.602 0 8.333-3.73 8.333-8.333 0-4.602-3.73-8.333-8.333-8.333zm0 1.666v13.334c-3.682 0-6.667-2.985-6.667-6.667S12.318 9.333 16 9.333z" />
    </svg>
  </Container>
);

export default Contrast;
