import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { WHITE } = colors;
const { ICON_SIGN_OUT } = ReactTestingLibraryDataProperties;

const SignOut: FC<Icon> = ({ size = 1.5, color = WHITE, fillOpacity = 1 }) => (
  <Container size={size} testId={ICON_SIGN_OUT}>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M10 3.63v.537h1.454c.316 0 .578.235.62.54l.005.084.006 3.543h-1.25l-.005-2.917H10v3.754h.835v-.004h1.252l-.001.004h4.116l-1.436-1.437a.625.625 0 01-.06-.814l.06-.07a.625.625 0 01.814-.06l.07.06 2.498 2.498a.625.625 0 01.06.814l-.06.07-2.497 2.503a.625.625 0 01-.945-.813l.06-.07 1.427-1.431H10v3.747h.845l-.006-2.917h1.251l.006 3.541a.625.625 0 01-.54.62l-.085.007h-1.47v.623a.625.625 0 01-.735.616l-7.083-1.251a.625.625 0 01-.516-.616V4.792c0-.306.221-.567.523-.616l7.084-1.164A.625.625 0 0110 3.63zm-1.25.735l-5.833.958v8.944l5.833 1.03zM7.085 9.584a.835.835 0 110 1.67.835.835 0 010-1.67z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  </Container>
);

export default SignOut;
