import {
  EMAIL_SUPPORT,
  ENTERPRISE_PERFORMANCE_GUIDE_TEXT,
  ENTERPRISE_USER_GUIDE_TEXT,
  ENTERPRISE_REGULATORY_CERTIFICATIONS_TEXT,
  INFO_BOX_READ_USER_GUIDE,
  INFO_BOX_WARNING,
  MAIL_TO_HREF,
  PRIVACY_POLICY_TEXT,
  LEGAL_NOTICES_TEXT,
} from 'components/Nav/constants';
import {
  LINK_ENTERPRISE_PERFOMANCE_GUIDE,
  LINK_ENTERPRISE_USER_GUIDE,
  LINK_PRIVACY_POLICY,
  LINK_TERMS_CONDITIONS,
  LINK_ENTERPRISE_REGULATORY_CERTIFICATIONS,
} from 'constants/links';
import IsoAlert from 'icons/IsoAlert';
import UserGuide from 'icons/UserGuide';
import { DocumentationItemType } from './AboutModal.types';

export const ABOUT_WEB_VIEWER_TEXT = 'About Web Viewer';
export const ABOUT_WEB_VIEWER_MODAL_SUBTITLE_TEXT = 'Annalise Enterprise clinical decision support';
export const ABOUT_WEB_VIEWER_MODAL_TITLE_TEXT = 'Annalise Web Viewer';
export const DOCUMENTATION_SUBHEADER = 'Label';
export const EMAIL_TEXT = 'Email';
export const SUPPORT_SUBHEADER = 'Support';
export const UDI_TITLE = 'UDI:';
export const UDI_WEB_VERSION = '+G140ANNALISEWEBVIEWER10/$$+7WV-1.1.0D';
export const VERSION_WEB_SUBHEADER = 'Web Viewer Version';
export const VERSION_ENTERPRISE_SUBHEADER = 'Enterprise Version';
export const VERSION_TITLE = 'Version:';

export const KEY_PRIVACY_POLICY = 'privacyPolicy';
export const KEY_USER_GUIDE = 'userGuide';
export const KEY_LEGAL_NOTICES = 'legalNotices';
export const KEY_PERFORMANCE_SPECS = 'performanceSpecifications';
export const KEY_REGULATORY_CERTIFICATIONS = 'regulatoryCertification';

export const documentationItems: DocumentationItemType = [
  {
    key: KEY_USER_GUIDE,
    text: ENTERPRISE_USER_GUIDE_TEXT,
    link: LINK_ENTERPRISE_USER_GUIDE,
  },
  {
    key: KEY_PERFORMANCE_SPECS,
    text: ENTERPRISE_PERFORMANCE_GUIDE_TEXT,
    link: LINK_ENTERPRISE_PERFOMANCE_GUIDE,
  },
  {
    key: KEY_LEGAL_NOTICES,
    text: LEGAL_NOTICES_TEXT,
    link: LINK_TERMS_CONDITIONS,
  },
  {
    key: KEY_PRIVACY_POLICY,
    text: PRIVACY_POLICY_TEXT,
    link: LINK_PRIVACY_POLICY,
  },
  {
    key: KEY_REGULATORY_CERTIFICATIONS,
    text: ENTERPRISE_REGULATORY_CERTIFICATIONS_TEXT,
    link: LINK_ENTERPRISE_REGULATORY_CERTIFICATIONS,
  },
];

export const supportItems = {
  email: EMAIL_SUPPORT,
  mailToHref: MAIL_TO_HREF,
  infoPanelItems: [
    { icon: <IsoAlert />, text: INFO_BOX_WARNING },
    { icon: <UserGuide />, text: INFO_BOX_READ_USER_GUIDE },
  ],
};
