import { push } from 'connected-react-router';
import React, { FC, FocusEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';
import { ButtonSize, ButtonVariant } from 'components/Buttons/Button/Button';
import Menu from 'components/Menu/Menu';
import { Divider, MenuItem as Item } from 'components/Menu/Menu.styles';
import { MenuTypes } from 'components/Menu/Menu.types';
import AboutAnnalise from 'components/Menu/MenuLink/AboutModal/AboutModal';
import MenuLink from 'components/Menu/MenuLink/MenuLink';
import { DEMO_MENU_CONFIG, VIEWER_MENU_CONFIG } from 'components/Nav/navMenuConfig';
import configuration from 'configuration';
import colors from 'constants/colors';
import { BREAK_POINTS } from 'constants/sizes';
import LogoSizes from 'enums/LogoSizes';
import Routes from 'enums/Routes';
import ScreenVariant from 'enums/ScreenVariant';
import tokenHelpers from 'helpers/cookies/tokenHelper';
import decodeAuthorizationPayload from 'helpers/decodeAuthorizationPayload';
import { isLoggedIn } from 'helpers/limitationHelper';
import { Breadcrumbs, BreadcrumbsSolid } from 'icons/Breadcrumbs';
import LogoVariant from 'icons/LogoVariant';
import { BreadcrumbButton, Container, LogoWrapper, NavContainer } from './LogoNav.styles';
import { LogoNavProps } from './LogoNav.types';

const { ICON } = ButtonSize;
const { DEFAULT } = ButtonVariant;
const { XL } = LogoSizes;
const { HEADER_ICON_SHRINK_APP_WIDTH } = BREAK_POINTS;
const { HOME, LIST } = Routes;
const { DEMO } = ScreenVariant;
const { NEUTRAL_BACKGROUND_ON_VARIANT } = colors;

const LogoNav: FC<LogoNavProps> = ({ className, size = XL, shouldRedirect = true, screenVariant = DEMO }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isDemo } = configuration;
  const BreadcrumbsIcon = isMenuOpen ? BreadcrumbsSolid : Breadcrumbs;
  const { NAV } = MenuTypes;
  const { isViewer } = configuration;
  const { pathname } = useLocation();

  const [isCurrentlyLoggedIn, setIsCurrentlyLoggedIn] = useState(false);
  const { idToken } = tokenHelpers.retrieveTokens();
  const { scopes } = decodeAuthorizationPayload(idToken);

  const dispatch = useDispatch();
  const shouldReduceHeader = useMediaQuery({
    maxWidth: HEADER_ICON_SHRINK_APP_WIDTH,
  });

  const showMenu = () => {
    setIsMenuOpen(true);
  };

  const hideMenu = () => {
    setIsMenuOpen(false);
  };

  const onLogoClick = () => {
    if (shouldRedirect) {
      if (isDemo && isLoggedIn()) {
        dispatch(push(LIST));
      } else {
        dispatch(push(HOME));
      }
    }
  };

  useEffect(() => {
    setIsCurrentlyLoggedIn(isLoggedIn());
  }, [isMenuOpen]);

  const appModeMenu = isViewer ? VIEWER_MENU_CONFIG : DEMO_MENU_CONFIG;

  const menuItems = appModeMenu.map((config, index) => {
    const { shouldShow, type } = config;
    if (
      !shouldShow({
        pathname,
        isCurrentlyLoggedIn,
        isViewer,
        userScopes: scopes,
      })
    ) {
      return null;
    }

    if (type === 'DIVIDER') {
      return <Divider key={`divider-${index}`} />;
    }

    const { text } = config;

    return <Item key={text}>{type === 'MODAL' ? <AboutAnnalise {...config} /> : <MenuLink {...config} />}</Item>;
  });

  const handleBlur = (e: FocusEvent) => {
    const { currentTarget } = e;
    requestAnimationFrame(() => {
      if (!currentTarget?.contains(document.activeElement)) {
        hideMenu();
      }
    });
  };

  return (
    <Container className={className} shouldReduceHeader={shouldReduceHeader} size={size}>
      <LogoWrapper onClick={onLogoClick}>
        <LogoVariant variant={screenVariant} />
      </LogoWrapper>
      <NavContainer onBlur={handleBlur} onMouseEnter={showMenu} onMouseLeave={hideMenu}>
        <BreadcrumbButton size={ICON} variant={DEFAULT} onClick={showMenu}>
          <BreadcrumbsIcon color={NEUTRAL_BACKGROUND_ON_VARIANT} />
        </BreadcrumbButton>
        <Menu menuType={NAV} className={className} isVisible={isMenuOpen}>
          {menuItems}
        </Menu>
      </NavContainer>
    </Container>
  );
};

export default LogoNav;
