import { Status } from '@annaliseai/api-specifications';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { isProcessing } from 'components/WorklistTable/utils/WorklistTable.utils';
import {
  AIFindingsContainer,
  CellContent,
  CellInterior,
  DateCellInterior,
  IconWrapper,
  PatientName,
  RightAlignedCellInterior,
  rowStatusConfig,
  rowStylingConfig,
  Tag,
} from 'components/WorklistTable/WorklistTable.styles';
import colors from 'constants/colors';
import { FONT_WEIGHTS } from 'constants/sizes';
import IconDirection from 'enums/IconDirection';
import formatPatientAgeSex from 'helpers/formatPatientAgeSex';
import maybeTruncate from 'helpers/maybeTruncate';
import ChevronBold from 'icons/ChevronBold';
import DisplayStudyInformation from 'types/DisplayStudyInformation';
import maybeEnableHiding from './utils/maybeEnableHiding';
import maybeShowColumn from './utils/maybeShowColumn';
import {
  ACC,
  ACC_COL,
  AI_FINDINGS,
  AI_FINDINGS_COL,
  DATE_ANALYSED,
  DATE_ANALYSED_COL,
  DATE_OF_STUDY,
  DATE_OF_STUDY_COL,
  DEPARTMENT,
  DEPARTMENT_COL,
  ERROR,
  INSTITUTION,
  INSTITUTION_COL,
  PATIENT_AGE_SEX,
  PATIENT_AGE_SEX_COL,
  PATIENT_DOB,
  PATIENT_DOB_COL,
  PATIENT_ID,
  PATIENT_ID_COL,
  PATIENT_NAME,
  PATIENT_NAME_COL,
  PLACEHOLDER,
  PROCESSING,
  ROW_WIDTH_CONFIG,
  SCAN,
  SCAN_COL,
} from './WorklistTable.constants';
import { ColumnIds } from './WorklistTypes';

const { COMPLETED, COMPLETED_ERROR } = Status;
const { SECONDARY_ON } = colors;
const { RIGHT } = IconDirection;
const { REGULAR, BOLD } = FONT_WEIGHTS;

const {
  DATE_ANALYSED_CONFIG,
  DATE_OF_STUDY_CONFIG,
  PATIENT_ID_CONFIG,
  PATIENT_AGE_CONFIG,
  PATIENT_NAME_CONFIG,
  PATIENT_DOB_CONFIG,
  ACC_CONFIG,
  SCAN_CONFIG,
  INSTITUTION_CONFIG,
  DEPARTMENT_CONFIG,
} = ROW_WIDTH_CONFIG;

const columnHelper = createColumnHelper<DisplayStudyInformation>();
const worklistColumns = [
  columnHelper.accessor(row => row.studyDateTime, {
    header: DATE_OF_STUDY,
    id: DATE_OF_STUDY_COL,
    enableHiding: maybeEnableHiding(DATE_OF_STUDY_COL),
    meta: {
      style: {
        width: DATE_OF_STUDY_CONFIG.width,
      },
    },
    cell: row => {
      const data = row.row.original;
      const { studyTime, studyDate } = data;
      return (
        <DateCellInterior>
          <CellContent title={studyTime}>{studyTime || PLACEHOLDER}</CellContent>
          <CellContent title={studyDate}>{studyDate || PLACEHOLDER}</CellContent>
        </DateCellInterior>
      );
    },
  }),
  columnHelper.accessor(row => row.updatedDateTime, {
    header: DATE_ANALYSED,
    id: DATE_ANALYSED_COL,
    enableHiding: maybeEnableHiding(DATE_ANALYSED_COL),
    meta: {
      style: {
        width: DATE_ANALYSED_CONFIG.width,
      },
    },
    cell: row => {
      const data = row.row.original;
      const { updatedTime, updatedDate } = data;
      return (
        <DateCellInterior>
          <CellContent title={updatedTime}>{updatedTime}</CellContent>
          <CellContent title={updatedDate}>{updatedDate}</CellContent>
        </DateCellInterior>
      );
    },
  }),
  columnHelper.accessor(row => row.patientId, {
    header: PATIENT_ID,
    id: PATIENT_ID_COL,
    enableHiding: maybeEnableHiding(PATIENT_ID_COL),
    meta: {
      getProps: (context: CellContext<DisplayStudyInformation, unknown>) => {
        const { status, patientId } = context.row.original;
        return {
          style: {
            fontWeight: (isProcessing(status) && REGULAR) || BOLD,
            width: PATIENT_ID_CONFIG.width,
          },
          title: patientId,
        };
      },
    },
    cell: row => {
      const { patientId } = row.row.original;
      const { truncationLength } = PATIENT_ID_CONFIG;
      return (
        <CellInterior>
          <CellContent>{(patientId && maybeTruncate(patientId, truncationLength)) || PLACEHOLDER}</CellContent>
        </CellInterior>
      );
    },
  }),
  columnHelper.accessor(row => row.patientName, {
    header: PATIENT_NAME,
    id: PATIENT_NAME_COL,
    enableHiding: maybeEnableHiding(PATIENT_NAME_COL),
    meta: {
      getProps: (context: CellContext<DisplayStudyInformation, unknown>) => {
        const { patientName } = context.row.original;
        return {
          style: {
            width: PATIENT_NAME_CONFIG.width,
          },
          title: patientName,
        };
      },
    },
    cell: row => {
      const data = row.row.original;
      const { patientName, status } = data;
      const fontWeight = (isProcessing(status) && REGULAR) || BOLD;
      const { truncationLength } = PATIENT_NAME_CONFIG;
      return (
        <CellInterior>
          <PatientName fontWeight={fontWeight}>
            {(patientName && maybeTruncate(patientName, truncationLength)) || PLACEHOLDER}
          </PatientName>
        </CellInterior>
      );
    },
  }),
  columnHelper.accessor(row => row.patientAge, {
    header: PATIENT_AGE_SEX,
    id: PATIENT_AGE_SEX_COL,
    enableHiding: maybeEnableHiding(PATIENT_AGE_SEX_COL),
    meta: {
      getProps: (context: CellContext<DisplayStudyInformation, unknown>) => {
        const { patientAge, patientSex } = context.row.original;
        return {
          style: {
            width: PATIENT_AGE_CONFIG.width,
          },
          title: formatPatientAgeSex(patientAge, patientSex),
        };
      },
    },
    cell: row => {
      const data = row.row.original;
      const { patientAge, patientSex, status } = data;
      const fontWeight = (isProcessing(status) && REGULAR) || BOLD;
      return (
        <CellInterior>
          <CellContent fontWeight={fontWeight}>{formatPatientAgeSex(patientAge, patientSex, PLACEHOLDER)}</CellContent>
        </CellInterior>
      );
    },
  }),
  columnHelper.accessor(row => row.patientBirthDate, {
    header: PATIENT_DOB,
    id: PATIENT_DOB_COL,
    enableHiding: maybeEnableHiding(PATIENT_DOB_COL),
    meta: {
      getProps: (context: CellContext<DisplayStudyInformation, unknown>) => {
        const { patientBirthDate } = context.row.original;
        return {
          style: {
            width: PATIENT_DOB_CONFIG.width,
          },
          title: patientBirthDate,
        };
      },
    },
    cell: row => {
      const data = row.row.original;
      const { patientBirthDate, status } = data;
      const fontWeight = (isProcessing(status) && REGULAR) || BOLD;
      return (
        <CellInterior>
          <CellContent fontWeight={fontWeight}>{patientBirthDate || PLACEHOLDER}</CellContent>
        </CellInterior>
      );
    },
  }),
  columnHelper.accessor(row => row.accessionNumber, {
    header: ACC,
    id: ACC_COL,
    enableHiding: maybeEnableHiding(ACC_COL),
    meta: {
      getProps: (context: CellContext<DisplayStudyInformation, unknown>) => {
        const { status, accessionNumber } = context.row.original;
        return {
          style: {
            fontWeight: (isProcessing(status) && REGULAR) || BOLD,
            width: ACC_CONFIG.width,
          },
          title: accessionNumber,
        };
      },
    },
    cell: row => {
      const { accessionNumber } = row.row.original;
      const { truncationLength } = ACC_CONFIG;
      return (
        <CellInterior>
          <CellContent>{maybeTruncate(accessionNumber, truncationLength) || PLACEHOLDER}</CellContent>
        </CellInterior>
      );
    },
  }),
  columnHelper.accessor(row => row.studyType, {
    header: SCAN,
    id: SCAN_COL,
    enableHiding: maybeEnableHiding(SCAN_COL),
    meta: {
      getProps: (context: CellContext<DisplayStudyInformation, unknown>) => {
        const { status } = context.row.original;
        return {
          style: {
            fontWeight: (isProcessing(status) && REGULAR) || BOLD,
            width: SCAN_CONFIG.width,
          },
        };
      },
    },
  }),
  columnHelper.accessor(row => row.institutionName, {
    header: INSTITUTION,
    id: INSTITUTION_COL,
    enableHiding: maybeEnableHiding(INSTITUTION_COL),
    meta: {
      getProps: (context: CellContext<DisplayStudyInformation, unknown>) => {
        const { status } = context.row.original;
        return {
          style: {
            fontWeight: (isProcessing(status) && REGULAR) || BOLD,
            width: INSTITUTION_CONFIG.width,
          },
        };
      },
    },
    cell: row => {
      const { institutionName } = row.row.original;
      const { truncationLength } = DEPARTMENT_CONFIG;
      return (
        <CellInterior>
          <CellContent title={institutionName}>
            {(institutionName && maybeTruncate(institutionName, truncationLength)) || PLACEHOLDER}
          </CellContent>
        </CellInterior>
      );
    },
  }),
  columnHelper.accessor(row => row.requestingService, {
    header: DEPARTMENT,
    id: DEPARTMENT_COL,
    enableHiding: maybeEnableHiding(DEPARTMENT_COL),
    meta: {
      getProps: (context: CellContext<DisplayStudyInformation, unknown>) => {
        const { status, requestingService } = context.row.original;
        return {
          style: {
            fontWeight: (isProcessing(status) && REGULAR) || BOLD,
            width: DEPARTMENT_CONFIG.width,
          },
          title: requestingService,
        };
      },
    },
    cell: row => {
      const { requestingService } = row.row.original;
      const { truncationLength } = DEPARTMENT_CONFIG;
      return (
        <CellInterior>
          <CellContent>
            {(requestingService && maybeTruncate(requestingService, truncationLength)) || PLACEHOLDER}
          </CellContent>
        </CellInterior>
      );
    },
  }),
  columnHelper.accessor(row => row.priority, {
    header: AI_FINDINGS,
    id: AI_FINDINGS_COL,
    enableHiding: maybeEnableHiding(AI_FINDINGS_COL),
    cell: row => {
      const data = row.row.original;
      const { priority, rank, status } = data;
      const hasPriority = priority && rank && rank in rowStylingConfig;
      const isError = status === COMPLETED_ERROR;

      return (
        <RightAlignedCellInterior>
          <AIFindingsContainer>
            <CellContent>
              {(hasPriority && <Tag tagColor={rowStylingConfig[rank].tagColor}>{priority}</Tag>) ||
                (isProcessing(status) && PROCESSING) ||
                (isError && <Tag tagColor={rowStatusConfig[COMPLETED_ERROR].tagColor}>{ERROR}</Tag>) ||
                PLACEHOLDER}
            </CellContent>
            <CellContent>
              <IconWrapper>
                {hasPriority && rowStylingConfig[rank]?.icon}
                {status === COMPLETED && (
                  <ChevronBold
                    direction={RIGHT}
                    fill={(hasPriority && rowStylingConfig[rank]?.color) || SECONDARY_ON}
                    size={1.5}
                    shadow={'transparent'}
                  />
                )}
              </IconWrapper>
            </CellContent>
          </AIFindingsContainer>
        </RightAlignedCellInterior>
      );
    },
  }),
  // Filter out any columns which should be completely removed
].filter(({ id }) => maybeShowColumn(id as ColumnIds));

export default worklistColumns;
