import React from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Container from 'icons/Container';

const { CHECKBOX_CHECKED, CHECKBOX_UNCHECKED } = ReactTestingLibraryDataProperties;

export const CheckboxChecked = (): JSX.Element => {
  return (
    <Container testId={CHECKBOX_CHECKED}>
      <svg role="img" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path d="M13.4062 2.25C14.6489 2.25 15.6562 3.25736 15.6562 4.5V13.5C15.6562 14.7426 14.6489 15.75 13.4062 15.75H4.40625C3.16361 15.75 2.15625 14.7426 2.15625 13.5V4.5C2.15625 3.25736 3.16361 2.25 4.40625 2.25H13.4062ZM12.2585 5.97725L7.40625 10.8295L5.554 8.97725C5.33433 8.75758 4.97817 8.75758 4.7585 8.97725C4.53883 9.19692 4.53883 9.55308 4.7585 9.77275L7.0085 12.0227C7.22817 12.2424 7.58433 12.2424 7.804 12.0227L13.054 6.77275C13.2737 6.55308 13.2737 6.19692 13.054 5.97725C12.8343 5.75758 12.4782 5.75758 12.2585 5.97725Z" />
      </svg>
    </Container>
  );
};

export const CheckboxUnchecked = (): JSX.Element => {
  return (
    <Container testId={CHECKBOX_UNCHECKED}>
      <svg role="img" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path d="M4.3125 2.25H13.6875C14.8266 2.25 15.75 3.17341 15.75 4.3125V13.6875C15.75 14.8266 14.8266 15.75 13.6875 15.75H4.3125C3.17341 15.75 2.25 14.8266 2.25 13.6875V4.3125C2.25 3.17341 3.17341 2.25 4.3125 2.25ZM4.3125 3.375C3.79473 3.375 3.375 3.79473 3.375 4.3125V13.6875C3.375 14.2053 3.79473 14.625 4.3125 14.625H13.6875C14.2053 14.625 14.625 14.2053 14.625 13.6875V4.3125C14.625 3.79473 14.2053 3.375 13.6875 3.375H4.3125Z" />
      </svg>
    </Container>
  );
};
