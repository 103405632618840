import { StudyType } from '@annaliseai/api-specifications';
import INPUTS from 'constants/inputs';

const { CXR, CTB } = StudyType;

const {
  ANY_TIME,
  LAST_24_HOURS,
  LAST_48_HOURS,
  LAST_7_DAYS,
  CUSTOM_DATES,
  STUDY_TYPE_ALL,
  STUDY_TYPE_LABEL,
  INSTITUTION_NAME_ALL,
  INSTITUTION_NAME_LABEL,
  REQUESTING_SERVICES_ALL,
  REQUESTING_SERVICES_LABEL,
  SEARCH_LABEL,
  SEARCH_PLACEHOLDER,
  DATE_TYPE_LABEL,
  CREATED_AFTER_LABEL,
  CREATED_UNTIL_LABEL,
  CREATED_AFTER_INPUT_LABEL,
  CREATED_UNTIL_INPUT_LABEL,
  SEARCH_FORM_LABEL,
  SEARCH_BUTTON_LABEL,
  RESET_BUTTON_LABEL,
  CLEAR_SEARCH_BUTTON_LABEL,
  SAVE_FILTERS_BUTTON_LABEL,
  FILTERS_SAVED_BUTTON_LABEL,
  SEARCH_TITLE,
  STUDY_TYPE_TITLE,
  DATE_TYPE_TITLE,
  CREATED_AFTER_TITLE,
  CREATED_UNTIL_TITLE,
  RESET_BUTTON_TITLE,
  CLEAR_SEARCH_BUTTON_TITLE,
  SAVE_FILTERS_BUTTON_TITLE,
  FILTERS_SAVED_BUTTON_TITLE,
} = INPUTS;

export const DATE_TYPES_OPTIONS = [
  { name: ANY_TIME, value: '' },
  { name: LAST_24_HOURS, value: LAST_24_HOURS },
  { name: LAST_48_HOURS, value: LAST_48_HOURS },
  { name: LAST_7_DAYS, value: LAST_7_DAYS },
  { name: CUSTOM_DATES, value: CUSTOM_DATES },
];

export const STUDY_TYPES_OPTIONS = [
  { name: STUDY_TYPE_ALL, value: '' },
  { name: CXR, value: CXR },
  { name: CTB, value: CTB },
];

export const INSTITUTION_NAME_OPTIONS = [{ name: INSTITUTION_NAME_ALL, value: '' }];

export const REQUESTING_SERVICES_OPTIONS = [{ name: REQUESTING_SERVICES_ALL, value: '' }];

export const SEARCH_FORM_ATTRS = {
  name: 'searchfilter',
  'aria-label': SEARCH_FORM_LABEL,
  role: 'search',
};

const SEARCH_ATTRS = {
  name: 'search',
  'aria-label': SEARCH_LABEL,
  placeholder: SEARCH_PLACEHOLDER,
  title: SEARCH_TITLE,
};

const INSTITUTION_NAME_ATTRS = {
  name: 'institutionName',
  'aria-label': INSTITUTION_NAME_LABEL,
};

const REQUESTING_SERVICES_ATTRS = {
  name: 'requestingService',
  'aria-label': REQUESTING_SERVICES_LABEL,
};

const STUDY_TYPE_ATTRS = {
  name: 'studyType',
  'aria-label': STUDY_TYPE_LABEL,
  title: STUDY_TYPE_TITLE,
};

const DATE_TYPE_ATTRS = {
  name: 'dateType',
  'aria-label': DATE_TYPE_LABEL,
  title: DATE_TYPE_TITLE,
};

const INPUT_TYPE_DATE = 'date';

const CREATED_AFTER_ATTRS = {
  name: 'createdAfter',
  'aria-label': CREATED_AFTER_LABEL,
  type: INPUT_TYPE_DATE,
  title: CREATED_AFTER_TITLE,
};

const CREATED_AFTER_INPUT_ATTRS = {
  name: 'createdAfterInput',
  'aria-label': CREATED_AFTER_INPUT_LABEL,
};

const CREATED_UNTIL_ATTRS = {
  name: 'createdUntil',
  'aria-label': CREATED_UNTIL_LABEL,
  type: INPUT_TYPE_DATE,
  title: CREATED_UNTIL_TITLE,
};

const CREATED_UNTIL_INPUT_ATTRS = {
  name: 'createdUntilInput',
  'aria-label': CREATED_UNTIL_INPUT_LABEL,
};

export const INPUT_ELEMENTS = {
  SEARCH_ATTRS,
  INSTITUTION_NAME_ATTRS,
  REQUESTING_SERVICES_ATTRS,
  STUDY_TYPE_ATTRS,
  DATE_TYPE_ATTRS,
  CREATED_AFTER_ATTRS,
  CREATED_AFTER_INPUT_ATTRS,
  CREATED_UNTIL_ATTRS,
  CREATED_UNTIL_INPUT_ATTRS,
};

const SEARCH_BUTTON_ATTRS = {
  name: 'searchButton',
  'aria-label': SEARCH_BUTTON_LABEL,
};

const RESET_BUTTON_ATTRS = {
  name: 'resetButton',
  'aria-label': RESET_BUTTON_LABEL,
  title: RESET_BUTTON_TITLE,
};

const CLEAR_SEARCH_ATTRS = {
  name: 'clearSearchButton',
  'aria-label': CLEAR_SEARCH_BUTTON_LABEL,
  title: CLEAR_SEARCH_BUTTON_TITLE,
};

const SAVE_FILTERS_ATTRS = {
  name: 'saveFiltersButton',
  'aria-label': SAVE_FILTERS_BUTTON_LABEL,
  title: SAVE_FILTERS_BUTTON_TITLE,
};

const FILTERS_SAVED_ATTRS = {
  name: 'saveFiltersButton',
  'aria-label': FILTERS_SAVED_BUTTON_LABEL,
  title: FILTERS_SAVED_BUTTON_TITLE,
};

export const BUTTON_ELEMENTS = {
  SEARCH_BUTTON_ATTRS,
  RESET_BUTTON_ATTRS,
  CLEAR_SEARCH_ATTRS,
  FILTERS_SAVED_ATTRS,
  SAVE_FILTERS_ATTRS,
};

export const MINIMUM_DATE_SELECTION = '2000-01-01';
