import styled, { css, DataAttributes } from 'styled-components';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { MenuPosition, MenuProps } from './Menu.types';

const { SECONDARY, SECONDARY_OUTLINE, NEUTRAL_BACKGROUND } = colors;

const { TOP } = MenuPosition;
const CARET_HEIGHT_REM = 0.5;
const MENU_BORDER_RADIUS_REM = 0.5;
const TRANSFORM_BUFFER = 2.8;
const CARET_OFFSET = 0.05;

const { DIVIDER } = ReactTestingLibraryDataProperties;
export const caretBaseCss = css`
  content: '';
  position: absolute;
  top: 0;
  left: 1.2rem;
  width: 0;
  height: 0;
  border-left: ${CARET_HEIGHT_REM}rem solid transparent;
  border-right: ${CARET_HEIGHT_REM}rem solid transparent;
`;

// Allows easier positioning of the menu relative to the button
export const MenuWrapper = styled.div`
  height: 0;
  width: 0;
  position: absolute;
`;
export const MenuContainer = styled.div<MenuProps>`
  margin-left: -0.7rem;
  margin-top: 0.1rem;
  background-color: transparent !important;
  position: relative;

  /* caret background  - appears as border*/

  &::before {
    ${caretBaseCss};
    border-bottom: ${CARET_HEIGHT_REM}rem solid ${SECONDARY_OUTLINE};
  }

  /* caret foreground */

  &::after {
    ${caretBaseCss};
    border-bottom: ${CARET_HEIGHT_REM}rem solid ${SECONDARY};
    padding-top: 0.1rem;
  }

  // NB:  This styling will currently only work with the column selection menu as the menu heights are NOT FIXED
  ${({ position, $dynamicHeight = 1 }) =>
    position === TOP
      ? css`
          padding-bottom: ${CARET_HEIGHT_REM}rem;
          transform: translate(0, -${$dynamicHeight + TRANSFORM_BUFFER}rem);

          &::before {
            transform: rotate(180deg) translate(0, -${$dynamicHeight}rem);
          }

          &::after {
            transform: rotate(180deg) translate(0, -${$dynamicHeight - CARET_OFFSET}rem);
            padding-top: 0.1rem;
          }
        `
      : css`
          padding-top: ${CARET_HEIGHT_REM}rem;
          top: 0.05rem;
        `}

  ${({ isVisible }) =>
    isVisible
      ? css`
          display: block;
          border-radius: ${MENU_BORDER_RADIUS_REM}rem;
          background-color: ${NEUTRAL_BACKGROUND};
        `
      : css`
          display: none;
        `}
`;

export const ItemsContainer = styled.div`
  width: 15rem;
  border-radius: ${MENU_BORDER_RADIUS_REM}rem;
  background-color: ${SECONDARY};
  padding: 0.5rem;
  list-style: none;
  border: 1px solid ${SECONDARY_OUTLINE};
`;

export const MenuItem = styled.div`
  width: 100%;
  white-space: nowrap;
`;

export const Divider = styled.div.attrs<DataAttributes>({ 'data-testid': DIVIDER })`
  margin: 0.25rem 0.5rem;
  height: 1px;
  background-color: ${SECONDARY_OUTLINE};
`;
