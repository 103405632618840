import { ReactNode } from 'react';

export enum MenuTypes {
  MENU = 'menu',
  NAV = 'navigation',
}

export enum MenuPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}
export type MenuProps = {
  menuType?: MenuTypes;
  isVisible: boolean;
  children: ReactNode;
  className?: string;
  $dynamicHeight?: number;
  position?: MenuPosition;
};
