import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { WHITE } = colors;
const { ICON_UNDO } = ReactTestingLibraryDataProperties;

const Undo: FC<Icon> = ({ size, color = WHITE, fillOpacity = 1 }) => (
  <Container size={size} testId={ICON_UNDO}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      fillOpacity={fillOpacity}
    >
      <path d="M22.336 16c0 .939-.206 1.82-.619 2.645-.398.825-.96 1.522-1.685 2.091-.725.569-1.543.953-2.453 1.152-.91.185-1.814.163-2.71-.064-.881-.228-1.678-.633-2.389-1.216-.711-.583-1.252-1.301-1.621-2.155l-1.856.747c.497 1.138 1.216 2.105 2.154 2.901.939.797 1.999 1.351 3.179 1.664 1.195.299 2.404.32 3.627.064 1.223-.256 2.318-.76 3.285-1.514.967-.754 1.72-1.679 2.261-2.774.555-1.109.832-2.29.832-3.541 0-1.124-.227-2.197-.682-3.221-.455-1.024-1.095-1.913-1.92-2.667-.811-.754-1.75-1.316-2.816-1.685-1.067-.37-2.162-.505-3.286-.406-1.109.1-2.154.42-3.136.96-.981.527-1.813 1.238-2.496 2.134V8.66H8v5.334l1.003 1.002h4.672v-2.005h-2.539c.44-.768 1.024-1.4 1.75-1.899.725-.512 1.521-.846 2.389-1.002.881-.157 1.749-.114 2.602.128.868.227 1.636.625 2.304 1.194.683.57 1.21 1.252 1.579 2.048.384.797.576 1.643.576 2.539z" />
    </svg>
  </Container>
);

export default Undo;
