import { ReactElement } from 'react';
import { ItemsContainer, MenuContainer, MenuWrapper } from './Menu.styles';
import { MenuPosition, MenuProps, MenuTypes } from './Menu.types';

const { MENU } = MenuTypes;
const { BOTTOM } = MenuPosition;

const Menu = ({ menuType = MENU, children, isVisible, $dynamicHeight, position = BOTTOM }: MenuProps): ReactElement => {
  return (
    <MenuWrapper>
      <MenuContainer position={position} isVisible={isVisible} $dynamicHeight={$dynamicHeight} role={menuType}>
        <ItemsContainer>{children}</ItemsContainer>
      </MenuContainer>
    </MenuWrapper>
  );
};

export default Menu;
