import { colors as annaColors } from '@annaliseai/anna-design-tokens';
import styled, { css, DataAttributes } from 'styled-components';
import colors from 'constants/colors';
import Z_INDEXES from 'constants/zIndexes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { COLUMN_SELECTOR_LABEL, COLUMN_SELECTOR_TITLE } from './constants';

const { DARK_5 } = annaColors;
const { TERTIARY_OUTLINE_VARIANT } = colors;
const { MENU } = Z_INDEXES;
const { MENU: MENU_ID } = ReactTestingLibraryDataProperties;

const CARET_HEIGHT_REM = 0.5;
const caretBaseCss = css`
  content: '';
  position: absolute;
  bottom: -${CARET_HEIGHT_REM}rem;
  left: 1rem;
  width: 0;
  height: 0;
  border-left: ${CARET_HEIGHT_REM}rem solid transparent;
  border-right: ${CARET_HEIGHT_REM}rem solid transparent;
`;

export const ColumnSelectContainer = styled.div`
  width: 100%;
`;

export const ColumnSelectButton = styled.button.attrs({
  'aria-label': COLUMN_SELECTOR_LABEL,
  title: COLUMN_SELECTOR_TITLE,
})<{
  isActive: boolean;
}>`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.5rem;
  z-index: ${MENU};

  &:hover {
    background-color: ${DARK_5};
  }

  &:focus {
    outline: 1px solid ${TERTIARY_OUTLINE_VARIANT};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${DARK_5};
    `}
`;

export const ColumnSelectMenu = styled.div.attrs<DataAttributes>({ 'data-testid': MENU_ID })<{ isVisible: boolean }>`
  z-index: ${MENU};
  position: absolute;
  width: 15rem;
  border-radius: 0.625rem;
  background-color: ${DARK_5};
  display: flex;
  flex-direction: column;
  bottom: 2.8rem;
  padding: 1rem;
  left: -0.5rem;

  /* caret background */

  &::before {
    ${caretBaseCss};
    border-top: ${CARET_HEIGHT_REM}rem solid ${DARK_5};
  }

  /* caret foreground */

  &::after {
    ${caretBaseCss};
    border-top: ${CARET_HEIGHT_REM}rem solid ${DARK_5};
  }

  ${({ isVisible }) =>
    isVisible
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;
