import { ReactElement, ReactNode } from 'react';
import styled, { css, DataAttributes } from 'styled-components';

type IconContainerProps = {
  size?: number;
  testId: string;
  children?: ReactNode;
};

const IconContainer = styled.div.attrs<IconContainerProps & DataAttributes>(({ testId }) => ({
  'data-testid': testId,
}))<IconContainerProps>`
  ${({ size }) => css`
    width: ${size}rem;
    height: ${size}rem;
  `}
`;

const Container = ({ children, size, testId }: IconContainerProps): ReactElement => (
  <IconContainer {...{ size, testId }}>{children && children}</IconContainer>
);

export default Container;
