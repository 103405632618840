import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { WHITE } = colors;
const { SWAP, SWAP_SOLID } = ReactTestingLibraryDataProperties;

export const Swap: FC<Icon> = ({ size = 1.5, color = WHITE, fillOpacity = 1 }) => (
  <Container size={size} testId={SWAP}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
      <path
        d="M10.7829 0.219653L15.2779 4.71441C15.544 4.98047 15.5684 5.39676 15.351 5.69038L15.2785 5.77451L10.7835 10.2794C10.4909 10.5726 10.016 10.5731 9.72281 10.2805C9.45626 10.0145 9.4316 9.59791 9.64913 9.30406L9.72166 9.21986L12.9385 5.997L1.24268 5.99766C0.86298 5.99766 0.549185 5.71551 0.499523 5.34943L0.492676 5.24766C0.492676 4.86797 0.77483 4.55417 1.14091 4.50451L1.24268 4.49766L12.9405 4.497L9.72226 1.28035C9.45598 1.01409 9.43176 0.597427 9.6496 0.303808L9.72222 0.219687C9.98848 -0.046588 10.4051 -0.0708077 10.6988 0.147037L10.7829 0.219653L15.2779 4.71441L10.7829 0.219653ZM15.5007 14.6491L15.5075 14.7509C15.5075 15.1306 15.2254 15.4444 14.8593 15.494L14.7575 15.5009L3.06151 15.5L6.28268 18.7194C6.54905 18.9856 6.57343 19.4023 6.3557 19.696L6.28312 19.7801C6.01696 20.0465 5.60031 20.0709 5.30661 19.8531L5.22246 19.7806L0.72246 15.2843C0.456082 15.0182 0.431701 14.6015 0.649433 14.3078L0.722016 14.2237L5.22202 9.71989C5.51479 9.42688 5.98966 9.42668 6.28268 9.71945C6.54905 9.9856 6.57343 10.4023 6.3557 10.696L6.28312 10.7801L3.06551 14L14.7575 14.0009C15.1372 14.0009 15.451 14.283 15.5007 14.6491L15.5075 14.7509L15.5007 14.6491Z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  </Container>
);

export const SwapSolid: FC<Icon> = ({ size = 1.5, color = WHITE, fillOpacity = 1 }) => (
  <Container size={size} testId={SWAP_SOLID}>
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2069 0.291482L15.2069 4.28725C15.5676 4.64751 15.5956 5.21467 15.2909 5.60712L15.2078 5.70138L11.2078 9.70662C10.8175 10.0974 10.1843 10.0978 9.79354 9.70754C9.43283 9.34729 9.40472 8.78008 9.70949 8.38759L9.79262 8.29333L12.0832 5.99996L1.49951 5.99997C0.986676 5.99997 0.564005 5.61393 0.50624 5.11659L0.499512 4.99997C0.499512 4.48714 0.885552 4.06447 1.38289 4.0067L1.49951 3.99997L12.0902 3.99996L9.79346 1.70644C9.43278 1.34615 9.40475 0.778934 9.70957 0.386481L9.79271 0.29223C10.153 -0.0684449 10.7202 -0.0964744 11.1127 0.208343L11.2069 0.291482L15.2069 4.28725L11.2069 0.291482ZM15.4902 14.8834L15.497 15C15.497 15.5128 15.1109 15.9355 14.6136 15.9932L14.497 16L3.91319 16L6.20696 18.2925C6.56762 18.6528 6.59561 19.2201 6.29077 19.6125L6.20763 19.7067C5.84731 20.0674 5.2801 20.0954 4.88766 19.7906L4.79341 19.7074L0.793415 15.7112C0.432761 15.3509 0.404765 14.7836 0.709605 14.3912L0.792749 14.297L4.79275 10.2932C5.18309 9.90249 5.81626 9.90219 6.20696 10.2925C6.56762 10.6528 6.59561 11.2201 6.29077 11.6125L6.20763 11.7067L3.91719 14L14.497 14C15.0098 14 15.4325 14.386 15.4902 14.8834L15.497 15L15.4902 14.8834Z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  </Container>
);
