import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { WHITE } = colors;
const { ANNA_LOGO, ANNA_LOGO_SOLID } = ReactTestingLibraryDataProperties;

export const AnnaLogo: FC<Icon> = ({ size = 1.5, color = WHITE, fillOpacity = 1 }) => (
  <Container size={size} testId={ANNA_LOGO}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path
        d="M22.44 19.836 12.942 3.389a.775.775 0 0 0-1.344 0L2.103 19.836A.775.775 0 0 0 2.775 21s5.114-.004 5.114-.009c1.13-.055 2.34-.479 3.184-2.04l1.198-2.072 1.198 2.071c.886 1.64 2.179 2.024 3.356 2.046h4.943a.775.775 0 0 0 .671-1.16zm-5.5-.39c-.864 0-1.553-.21-2.105-1.232l-1.892-3.274a.775.775 0 0 0-1.344 0l-1.891 3.274c-.552 1.023-1.241 1.233-2.106 1.233H4.12L12.27 5.328l8.153 14.119H16.94z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  </Container>
);

export const AnnaLogoSolid: FC<Icon> = ({ size = 1.5, color = WHITE, fillOpacity = 1 }) => (
  <Container size={size} testId={ANNA_LOGO_SOLID}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path
        d="M22.44 19.836 12.942 3.389a.775.775 0 0 0-1.344 0L2.103 19.836A.775.775 0 0 0 2.775 21s5.114-.004 5.114-.009c1.13-.055 2.34-.479 3.184-2.04l1.198-2.072 1.198 2.071c.886 1.64 2.179 2.024 3.356 2.046h4.942a.775.775 0 0 0 .672-1.16z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  </Container>
);
