import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { BREADCRUMBS } = ReactTestingLibraryDataProperties;
const { WHITE } = colors;

export const Breadcrumbs: FC<Icon> = ({ size = 1.5, color = WHITE, fillOpacity = 1, fill }) => (
  <Container size={size} testId={BREADCRUMBS}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0H22C27.5228 0 32 4.47715 32 10V22C32 27.5228 27.5228 32 22 32H10C4.47715 32 0 27.5228 0 22V10Z"
        fill={fill}
      />
      <circle cx="7" cy="16" r="2.66" fill={color} fillOpacity={fillOpacity} />
      <circle cx="16" cy="16" r="2.66" fill={color} fillOpacity={fillOpacity} />
      <circle cx="25" cy="16" r="2.66" fill={color} fillOpacity={fillOpacity} />
    </svg>
  </Container>
);

export const BreadcrumbsSolid: FC<Icon> = ({ size = 1.5, color = WHITE, fillOpacity = 1, fill }) => (
  <Container size={size} testId={BREADCRUMBS}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0H22C27.5228 0 32 4.47715 32 10V22C32 27.5228 27.5228 32 22 32H10C4.47715 32 0 27.5228 0 22V10Z"
        fill={fill}
      />
      <circle cx="7" cy="16" r="2.66" fill={color} fillOpacity={fillOpacity} />
      <circle cx="16" cy="16" r="2.66" fill={color} fillOpacity={fillOpacity} />
      <circle cx="25" cy="16" r="2.66" fill={color} fillOpacity={fillOpacity} />
    </svg>
  </Container>
);
