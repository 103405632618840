import { FC } from 'react';
import Logo from 'icons/Logo';
import LoadingIndicatorContainer from './LoadingIndicator.styles';

type LoadingIndicatorProps = {
  show?: boolean;
  absoluteCentre?: boolean;
};

const LoadingIndicator: FC<LoadingIndicatorProps> = ({ show = true, absoluteCentre = true }) =>
  show ? (
    <LoadingIndicatorContainer absoluteCentre={absoluteCentre}>
      <Logo />
    </LoadingIndicatorContainer>
  ) : null;

export default LoadingIndicator;
