import styled, { DataAttributes, keyframes } from 'styled-components';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { LOADING_INDICATOR } = ReactTestingLibraryDataProperties;

const rotateAnimation = keyframes`
  to { transform: rotateY(360deg); }
`;

type LoadingIndicatorContainerProps = {
  absoluteCentre?: boolean;
};

const LoadingIndicatorContainer = styled.div.attrs<DataAttributes>({
  'data-testid': LOADING_INDICATOR,
})<LoadingIndicatorContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ absoluteCentre }) =>
    absoluteCentre &&
    `
      position: absolute;
      width: 100%;
      height: 100%;
  `}

  > svg {
    width: 44px;
    height: 38.5px;
    margin-bottom: 24px;
    animation: ${rotateAnimation} 1s infinite linear;
  }
`;

export default LoadingIndicatorContainer;
