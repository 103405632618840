import { colors } from '@annaliseai/anna-design-tokens';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Container from 'icons/Container';
import Icon from 'types/Icon';

const { WHITE } = colors;

const { ICON_COLUMNS } = ReactTestingLibraryDataProperties;
const Columns = ({ size = 1, color = WHITE }: Icon): JSX.Element => {
  return (
    <Container size={size} testId={ICON_COLUMNS}>
      <svg
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        width={`${size}rem`}
        height={`${size}rem`}
        viewBox="0 0 14 14"
        fill={color}
      >
        <path
          d="M0 1C0 0.447716 0.447715 0 1 0H3C3.55228 0 4 0.447715 4 1V13C4 13.5523 3.55229 14 3 14H1C0.447715 14 0 13.5523 0 13V1ZM3 1L1 1V13H3V1ZM5 1C5 0.447716 5.44771 0 6 0H8C8.55228 0 9 0.447715 9 1V13C9 13.5523 8.55229 14 8 14H6C5.44772 14 5 13.5523 5 13V1ZM8 1L6 1V13H8V1ZM11 0C10.4477 0 10 0.447716 10 1V13C10 13.5523 10.4477 14 11 14H13C13.5523 14 14 13.5523 14 13V1C14 0.447715 13.5523 0 13 0H11ZM11 1L13 1V13H11V1Z"
          fill={color}
        />
      </svg>
    </Container>
  );
};

export default Columns;
