import { DisplayFormat } from 'types/settings/DisplayData';

const DateFormats = {
  FORMAT_24H_TIME: (displayFormat?: DisplayFormat): string => displayFormat || 'HH:mm',
  FORMAT_12H_TIME: (displayFormat?: DisplayFormat): string => displayFormat || 'hh:mm a',
  FORMAT_24H_HH_MM_SS: (displayFormat?: DisplayFormat): string => displayFormat || 'H:mm:ss',
  FORMAT_12H_HH_MM_SS: (displayFormat?: DisplayFormat): string => displayFormat || 'hh:mm:ss A',
  FORMAT_D_MMM_YYYY: (displayFormat?: DisplayFormat): string => displayFormat || 'D MMM YYYY',
  FORMAT_D_MMMM_YYYY: (displayFormat?: DisplayFormat): string => displayFormat || 'D MMMM YYYY',
  FORMAT_DD_MMM_YYYY: (displayFormat?: DisplayFormat): string => displayFormat || 'DD MMM YYYY',
  FORMAT_D_MM_YYYY_SLASHES: (displayFormat?: DisplayFormat): string => displayFormat || 'D/MM/YYYY',
  FORMAT_YYYY_MM_DD_HYPHENS: (displayFormat?: DisplayFormat): string => displayFormat || 'YYYY-MM-DD',
  FORMAT_FULL_DAY_DATE_12HR_TIME: (displayFormat?: DisplayFormat): string =>
    displayFormat ? `dddd, ${displayFormat} h:mm:ss A` : 'dddd, D MMMM YYYY h:mm:ss A',
  FORMAT_FULL_DAY_DATE_24HR_TIME: (displayFormat?: DisplayFormat): string =>
    displayFormat ? `dddd, ${displayFormat} h:mm:ss A` : 'dddd, D MMMM YYYY H:mm:ss A',
};

export default DateFormats;
